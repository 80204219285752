import React from "react";

const Alert = (props) => {
  const { message, buttonTrue, buttonFalse, handleClick, show } = props;

  const buttonClick = ( answer) => {
    handleClick( answer);
  };

  return (
    <div className={show ? "AlertVisible" : "AlertHidden"}>
      <p>{message}</p>
      <span className="alertButtons" onClick={() => buttonClick( true)}>
        {buttonTrue}
      </span>
      <span className="alertButtons" onClick={() => buttonClick( false)}>
        {buttonFalse}
      </span>
    </div>
  );
};
export default Alert;
