import React, { useState, useEffect, useContext } from "react";
import { ScrapeContext } from "../../../../../../ScrapeContext";

import { CSVLink } from "react-csv";

import Item from "./Item";

import getCurrentDateAndTime from "../../../../../../../utils/getCurrentDateAndTime";

const Odd = (props) => {
  const scrapeData = useContext(ScrapeContext);
  let fetchTime = scrapeData.scrapes.fetchTime;
  let fetchPeriod = scrapeData.scrapes.fetchPeriod;
  let deadline = Date.parse(fetchTime) + fetchPeriod;

  const {
    items,
    setIsHidden,
    setCopied,
    activeBets,
    link,
    bookie,
    betAmount,
    handleBets,
    firstTeamName,
    newFirstTeamName,
    newSecondTeamName,
    secondTeamName,
  } = props;

  const bet = {
    time: "",
    name: "",
    notes: "",
    odds: [{ items: [] }],
  };
  //console.log("items", items);
  const [currItems, setCurrItems] = useState(items);
  const [notes, setNotes] = useState("");
  const [actBets, setActBets] = useState([]);
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    const lockIt = () => {
      let time = deadline - Date.parse(new Date());
      if (time < 0) {
        return;
      }
      let isLockedTime = time < 2000;
      setLocked(isLockedTime ? isLockedTime : false);
    };
    setActBets(activeBets);
    setInterval(() => lockIt(), 1000);
    if (locked) {
      setCurrItems(items);
    }

    return () => {
      lockIt();
    };
  }, [activeBets, locked, scrapeData, currItems, items, deadline]);

  const headers = [
    { label: "TIME AND DATE", key: "time" },
    { label: "BOOKIE", key: "name" },
    { label: "NOTES", key: "notes" },
    { label: "BET 1", key: "odds[0].items[0].name" },
    { label: "ODD 1", key: "odds[0].items[0].coef" },
    { label: "CASH 1", key: "odds[0].items[0].cash" },
    { label: "BET X", key: "odds[0].items[1].name" },
    { label: "ODD X", key: "odds[0].items[1].coef" },
    { label: "CASH X", key: "odds[0].items[1].cash" },
    { label: "BET 2", key: "odds[0].items[2].name" },
    { label: "ODD 2", key: "odds[0].items[2].coef" },
    { label: "CASH 2", key: "odds[0].items[2].cash" },
  ];

  const setBetDateAndTime = () => {
    bet.time = getCurrentDateAndTime();
  };

  const cashHandler = (value, itemId) => {
    currItems[itemId].cash = Number(value);
    setCurrItems(currItems);
  };

  const coefHandler = (value, itemId) => {
    currItems[itemId].coef = Number(value);
    setCurrItems(currItems);
  };

  const notesHandler = (e) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const handleNewBets = (bookie) => {
    bet.odds[0].items = currItems.filter((item) => item.cash > 0);
    bet.name = bookie.name;
    bet.notes = notes;
    setBetDateAndTime();
    handleBets({ firstTeamName, secondTeamName }, "update", bet);
  };

  return (
    <div className="tableRow">
      <span style={{ width: "3px" }}>{locked ? "🔒" : ""}</span>
      {currItems.map((item, id) => (
        <Item
          key={id}
          itemId={id}
          coef={item.coef}
          cash={item.cash}
          betAmount={betAmount}
          setIsHidden={setIsHidden}
          setCopied={setCopied}
          locked={locked}
          link={link}
          cashHandler={(value, itemId) => cashHandler(value, itemId)}
          coefHandler={(value, itemId) => coefHandler(value, itemId)}
        />
      ))}
      <div>
        <span className="doubleCell">
          <label>NOTES</label>
          <textarea
            defaultValue={notes}
            onChange={(e) => {
              notesHandler(e);
            }}
            className="tableInputsNotes"
            rows="1"
            cols="40"
          ></textarea>
        </span>
      </div>

      <CSVLink
        filename={`${newFirstTeamName} vs ${newSecondTeamName}-${getCurrentDateAndTime()}.csv`}
        data={actBets}
        target="_blank"
        headers={headers}
        className="betButton"
        asyncOnClick={true}
        onClick={(e) => {
          if (locked) {
            e.preventDefault();
            return;
          }
          let betsWithCash = currItems.filter((item) => item.cash > 0);
          if (
            betsWithCash.length > 0 &&
            betsWithCash.every((item) => item.cash && item.coef)
          ) {
            handleNewBets(bookie);
          } else {
            alert("ODD and 💵 in bet must have positive values!");
            e.preventDefault();
          }
        }}
      >
        💰
      </CSVLink>
    </div>
  );
};
export default Odd;
