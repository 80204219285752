import React, { useState } from "react";

import pickBookieLogo from "../../../../../utils/pickBookieLogo";
import Bookie from "./Bookies/Bookie";

const BookiesTable = (props) => {
  const {
    firstTeamName,
    handleFirstName,
    secondTeamName,
    handleSecondName,
    eventTime,
    eventDate,
    activeBets,
    bookies,
    handleBets,
  } = props;

  const [copied, setCopied] = useState("");
  const [isHidden, setIsHidden] = useState("isHidden");
  const [showBookies, setShowBookies] = useState(false);
  const [newFirstTeamName, setNewFirstTeamName] = useState(firstTeamName);
  const [newSecondTeamName, setNewSecondTeamName] = useState(secondTeamName);
  const [betAmount, setBetAmount] = useState("");

  const handleBetAmount = (e) => {
    e.preventDefault();
    setBetAmount(e.target.value);
  };

  const sumTotalBets = (arr, name) => {
    let betsTotal = 0;
    let cashValues = [];
    if (arr.length > 0) {
      arr.map((element) =>
        element.odds.map((odd) =>
          odd.items.map((item) =>
            item.name === name ? cashValues.push(item.cash) : null
          )
        )
      );
      betsTotal = cashValues.reduce((a, b) => a + b, 0);
    }
    return betsTotal;
  };

  const sumTotalWins = (arr, name) => {
    let winsTotal = 0;
    let cashAndCoefValues = [];
    if (arr.length > 0) {
      arr.map((element) =>
        element.odds.map((odd) =>
          odd.items.map((item) =>
            item.name === name
              ? cashAndCoefValues.push(item.cash * item.coef)
              : null
          )
        )
      );
      winsTotal = cashAndCoefValues.reduce((a, b) => a + b, 0);
    }
    return winsTotal;
  };

  const sumProfits = (activeBets, name) => {
    let betsTTL =
      sumTotalBets(activeBets, 1) +
      sumTotalBets(activeBets, "X") +
      sumTotalBets(activeBets, 2);

    let winsTotal = sumTotalWins(activeBets, name);

    return winsTotal === 0 ? winsTotal : winsTotal - betsTTL;
  };

  return (
    <div className="BookiesTable">
      <div>
        <p
          style={{
            fontSize: "0.7rem",
            background: "rgba(219, 219, 219, 0.6)",
          }}
        >
          event
        </p>
        <div style={{ display: "flex" }}>
          <span className="doubleCell">
            <span className="timeContainer">{eventTime}</span>
            <span>{eventDate}</span>
          </span>
          <span className="doubleCell">
            <input
              placeholder="First team Name"
              className="matchInputName"
              value={newFirstTeamName}
              onChange={(e) => {
                e.preventDefault();
                setNewFirstTeamName(e.target.value);
                handleFirstName(
                  firstTeamName,
                  secondTeamName,
                  newFirstTeamName
                );
              }}
            />
            <input
              placeholder="Second team Name"
              className="matchInputName"
              value={newSecondTeamName}
              onChange={(e) => {
                e.preventDefault();
                setNewSecondTeamName(e.target.value);
                handleSecondName(
                  firstTeamName,
                  secondTeamName,
                  newSecondTeamName
                );
              }}
            />
          </span>
          <span>
            <span style={{ display: "flex" }}>
              {activeBets && activeBets.length > 0
                ? activeBets.map((activeBet, id) => {
                    //console.log("bets", activeBet);
                    return (
                      <span className="doubleCell" key={id}>
                        <img
                          className="bookieLogo"
                          src={pickBookieLogo(activeBet.name)}
                          alt="bookieLogo"
                        />
                        <span>
                          {activeBet.odds.map((odd) =>
                            odd.items.map((item, id) => (
                              <span style={{ margin: "0px 2px" }} key={id}>
                                <span>{item.name}</span>
                                <span className="doubleCell">
                                  <span>
                                    {typeof item.coef !== "number"
                                      ? item.coef
                                      : item.coef.toFixed(2)}{" "}
                                    * {item.cash}💵
                                  </span>
                                </span>
                              </span>
                            ))
                          )}
                        </span>
                      </span>
                    );
                  })
                : null}
            </span>
          </span>
        </div>
        <div style={{ backgroundColor: "rgb(11, 21, 46)" }}>
          <button
            onClick={() =>
              handleBets(
                {
                  firstTeamName: firstTeamName,
                  secondTeamName: secondTeamName,
                },
                "delete"
              )
            }
          >
            DELETE
          </button>
          <button
            onClick={() => {
              setShowBookies(!showBookies);
            }}
          >
            view details
          </button>
          <label style={{ color: "white" }}>bet amount: </label>
          <input
            onChange={(e) => {
              handleBetAmount(e);
            }}
            className="tableInputs"
          />
        </div>
      </div>
      <div className={showBookies ? "MatchBets" : "isHidden"}>
        {activeBets && activeBets.length > 0
          ? activeBets.map((activeBet, id) => {
              return (
                <div style={{ backgroundColor: "rgb(233, 233, 233)" }} key={id}>
                  <div className="tableRowBets">
                    <span
                      style={{ padding: "0px 0px 0px 10px", width: "90px" }}
                    >
                      <img
                        className="bookieLogoVertical"
                        src={pickBookieLogo(activeBet.name)}
                        alt="bookieLogo"
                      />
                    </span>
                    {activeBet.odds.map((odd) =>
                      odd.items.map((item, id) => (
                        <span style={{ margin: "15px 20px" }} key={id}>
                          <span className="doubleCell">{item.name}</span>
                          <span>
                            {typeof item.coef !== "number"
                              ? item.coef
                              : item.coef.toFixed(2)}{" "}
                            *{" "}
                          </span>
                          <span>{item.cash}💵</span>
                        </span>
                      ))
                    )}
                    <span style={{ margin: "15px 20px" }}>
                      {activeBet.time}
                    </span>
                    <textarea
                      readOnly
                      defaultValue={activeBet.notes}
                      className="tableInputsNotes"
                      rows="1"
                      cols="40"
                    ></textarea>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {bookies.map((bookie, id) => (
        <Bookie
          key={id}
          showBookies={showBookies}
          name={bookie.name}
          bookie={bookie}
          setIsHidden={setIsHidden}
          setCopied={setCopied}
          activeBets={activeBets}
          handleBets={(event, action, bet) => handleBets(event, action, bet)}
          firstTeamName={firstTeamName}
          newFirstTeamName={newFirstTeamName}
          secondTeamName={secondTeamName}
          betAmount={betAmount}
          newSecondTeamName={newSecondTeamName}
        />
      ))}
      <div className={showBookies ? "calculations" : "isHidden"}>
        <span>
          <span className="tableBottom">
            <span>BETS</span>
            <div style={{ display: "flex" }}>
              <span>1</span>
              <span style={{ background: "white" }}>
                {sumTotalBets(activeBets, 1).toFixed(2)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>X</span>
              <span style={{ background: "white" }}>
                {sumTotalBets(activeBets, "X").toFixed(2)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>2</span>
              <span style={{ background: "white" }}>
                {sumTotalBets(activeBets, 2).toFixed(2)}
              </span>
            </div>
          </span>

          <div className="tableBottom">
            <span>WIN</span>
            <div style={{ display: "flex" }}>
              <span>1</span>
              <span style={{ background: "white" }}>
                {sumTotalWins(activeBets, 1).toFixed(2)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>X</span>
              <span style={{ background: "white" }}>
                {sumTotalWins(activeBets, "X").toFixed(2)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>2</span>
              <span style={{ background: "white" }}>
                {sumTotalWins(activeBets, 2).toFixed(2)}
              </span>
            </div>
          </div>

          <div className="tableBottom">
            <span>PROFIT</span>
            <div style={{ display: "flex" }}>
              <span>1</span>
              <span style={{ background: "white" }}>
                {sumProfits(activeBets, 1).toFixed(2)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>X</span>
              <span style={{ background: "white" }}>
                {sumProfits(activeBets, "X").toFixed(2)}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span>2</span>
              <span style={{ background: "white" }}>
                {sumProfits(activeBets, 2).toFixed(2)}
              </span>
            </div>
          </div>
        </span>
        <span className="TTLBets">
          <h5>TTL BETS</h5>
          {sumTotalBets(activeBets, 1) +
            sumTotalBets(activeBets, "X") +
            sumTotalBets(activeBets, 2)}
        </span>
      </div>

      <p className={isHidden}>{copied}</p>
    </div>
  );
};
export default BookiesTable;
