import { ScrapeProvider } from './ScrapeContext';
import { useState, useEffect } from 'react'

function useScrapes() {
  //Search for team by name
  //https://www.thesportsdb.com/api/v1/json/1/searchteams.php?t=Levski
  const [scrapes, setScrapes] = useState({
    webpages: []
  });

  const dev = false //server address

  const period = 180000

  useEffect(() => {
    // (1) define within effect callback scope
    const fetchData = async () => {
      console.log("Mounting or Updating " + new Date(Date.now()).toLocaleString());
      try {
        const res = dev ? await fetch('http://localhost:3333/data').then(res => res) : await fetch('https://top-zalozi.com/data').then(res => res)
        let data = await res.json();
        data.fetchTime = new Date();
        data.fetchPeriod = period
        console.log(data)
        setScrapes(data);

      } catch (error) {
        console.log(error);
      }
    };

    const id = setInterval(() => {
      fetchData(); // <-- (3) invoke in interval callback
    }, period, dev);

    fetchData(); // <-- (2) invoke on mount

    return () => clearInterval(id);
  }, [])
  return scrapes
}

export default function Context({ children }) {
  const scrapes = useScrapes();
  return <ScrapeProvider value={{
    scrapes,
  }}>
    <div>
      {children}
    </div>
  </ScrapeProvider>
}