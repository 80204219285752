import React from "react";

import Loader from "./Home/HighestOdds/Loader";

const Login = (props) => {
  const { username, handleUser, password, handlePass, buttonHandler } = props;
  return (
    <div className="login">
      <input
        style={{ top: "50%", left: "45%", position: "fixed" }}
        type="text"
        placeholder="user"
        value={username}
        onChange={(e) => {
          handleUser(e);
        }}
      />
      <input
        style={{ top: "55%", left: "45%", position: "fixed" }}
        type="password"
        placeholder="pass"
        value={password}
        onChange={(e) => {
          handlePass(e);
        }}
      />
      <button
        style={{
          top: "60%",
          left: "45%",
          position: "fixed",
          width: "145px",
        }}
        onClick={() => {
          buttonHandler();
        }}
      >
        try
      </button>
      <Loader />
    </div>
  );
};
export default Login;
