import React from "react";

const MatchHeader = (props) => {
  const { matchesLength, scrapeTime } = props;
  return (
    <div className="MatchHeader">
      <p className="darckBackground">
        found <b>{matchesLength}</b> events updated on server at: {scrapeTime} 
      </p>
      <div className="oddNameContainer">
        <span> %</span>
        <span>home</span>
        <span>draw</span>
        <span>away</span>
      </div>
    </div>
  );
};
export default MatchHeader;
