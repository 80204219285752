import Context from './components/Context';
import Home from './pages/Home';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Context>
          <Home />
        </Context>
      </header>
    </div>
  );
}

export default App;
