const CRUDEvents = (match, action, answer, bet, setShowAlert, setSelectedEvent, selectedEvents, setSelectedEvents) => {
    //console.log('CRUD', action, answer, bet)
    let clone;
    let id;
    let event;
    if (selectedEvents.length > 0) {
        //clone events
        clone = selectedEvents.map((elem) => elem);
        //find id of selected event
        selectedEvents.forEach((event, i) => {
            if (
                match.firstTeamName === event.firstTeamName &&
                match.secondTeamName === event.secondTeamName
            )
                id = i;
        })
    }

    switch (action) {

        case "create":
            // console.log("CREATE")
            if (match.hasOwnProperty("newFirstTeamName")) {
                console.log(id, match.firstTeamName, match.secondTeamName);
                clone[id].firstTeamName = match.firstTeamName;
                clone[id].secondTeamName = match.secondTeamName;
                setSelectedEvents(clone);
            } else {
                event = {
                    firstTeamName: match.firstTeamName,
                    secondTeamName: match.secondTeamName,
                    eventTime: match.eventTime,
                    eventDate: match.eventDate,
                    activeBets: [],
                    bookies: match.bookies
                }
                setSelectedEvents([...selectedEvents, event])
            }

            break;

        case "update":
            // console.log("UPDATE")
            clone[id].activeBets.push(bet)
            setSelectedEvents(clone);
            break;

        case "delete":
            if (answer === undefined) {
                //console.log("ALERT")
                setSelectedEvent(match)
                setShowAlert(true)
            }
            if (answer === true) {
                //console.log("DELETE")
                clone.splice(id, 1);
                setSelectedEvents(clone);
                setShowAlert(false)
            }
            if (answer === false) {
                // console.log("CLOSE")
                setShowAlert(false)
            }
            break;

        default:
            break;
    }
}
export default CRUDEvents;