import React, { useEffect, useState } from "react";
import BookiesTable from "./BookiesTable/BookiesTable";

import generateBookies from "../../../../utils/generateBookies";

import eventTemplate from "../../../eventTemplate";

const ActiveBets = (props) => {
  const { selectedEvents, handleBets, events } = props;
  const [selEvents, setSelEvents] = useState([]);

  let firstTeamName, secondTeamName, newFirstTeamName, newSecondTeamName;

  const handleFirstName = (ftn, stn, fn) => {
    firstTeamName = ftn;
    secondTeamName = stn;
    newFirstTeamName = fn;
    if (newSecondTeamName) newSecondTeamName = stn;
    handleBets(
      {
        firstTeamName,
        secondTeamName,
        newFirstTeamName,
        newSecondTeamName,
      },
      "create"
    );
  };

  const handleSecondName = (ftn, stn, sn) => {
    firstTeamName = ftn;
    secondTeamName = stn;
    newSecondTeamName = sn;
    if (newFirstTeamName) newFirstTeamName = ftn;
    handleBets(
      {
        firstTeamName,
        secondTeamName,
        newFirstTeamName,
        newSecondTeamName,
      },
      "create"
    );
  };

  //const tempEvents = selectedEvents.map((sel) => sel);
  selectedEvents.map((selEvent) => {
    let id;
    events.forEach((event, i) => {
      if (
        event.firstTeamName === selEvent.firstTeamName &&
        event.secondTeamName === selEvent.secondTeamName
      ) {
        return (id = i);
      }
    });
    selEvent.bookies = generateBookies(
      id === undefined ? [] : events[id].bookies
    );
    return null;
  });

  useEffect(() => {
    setSelEvents(selectedEvents);
  }, [selectedEvents]);

  const handleNewEventBets = (event, action, bet) => {
    //e.stopPropagation();
    handleBets(event, action, bet);
  };

  return (
    <div className="ActiveBets">
      <p className="darckBackground">
        Selected events: ({selEvents.length})
        <button onClick={() => handleNewEventBets(eventTemplate, "create")}>
          Create new
        </button>
      </p>
      <div className="betsContainer">
        {selEvents.length > 0
          ? selEvents.map((event, id) => {
              //console.log("selEve", event);
              return (
                <BookiesTable
                  key={id}
                  firstTeamName={event.firstTeamName}
                  handleFirstName={(ftn, fn) => {
                    handleFirstName(ftn, fn);
                  }}
                  secondTeamName={event.secondTeamName}
                  handleSecondName={(stn, sn) => {
                    handleSecondName(stn, sn);
                  }}
                  activeBets={event.activeBets}
                  activeBetsHandler={() => {}}
                  bookies={event.bookies}
                  handleBets={(event, action, bet) =>
                    handleNewEventBets(event, action, bet)
                  }
                  eventTime={event.eventTime}
                  eventDate={event.eventDate}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};
export default ActiveBets;
