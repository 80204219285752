import React from "react";

const Loader = () => {
  return (
    <div className="box">
      <div className="shadow"></div>
      <div className="gravity">
        <div className="ball"></div>
      </div>
    </div>
  );
};
export default Loader;
