import React from "react";

//images
import siteLogo from "../../../../images/logo192.png";
import Timer from "./Timer";

const Header = (props) => {
  const {
    handleSearch,
    searchValue,
    days,
    handleDate,
    percentLimit,
    handlePercent,
  } = props;

  const restartServer = async () => {
    const res = await fetch("https://top-zalozi.com/reset");
    const data = await res.json();
    console.log("Server restarted! Current scrapes: ", data);
  };

  const scrapeSites = async () => {
    const res = await fetch("https://top-zalozi.com/scrape");
    const data = await res.json();
    console.log("Sites are scraping! Current scrapes: ", data);
  };

  return (
    <div className="pageTitleContainer">
      <span className="doubleCell">
        <span className="siteLogoContainer">
          <span className="pageTitle">F</span>
          <img className="siteLogo" src={siteLogo} alt="FODDS LOGO" />
          <span className="pageTitle">dds.com</span>
        </span>
        <span>
          <button onClick={() => restartServer()}>restart server</button>
          <button onClick={() => scrapeSites()}>scrape sites</button>
        </span>
      </span>

      <input
        className="searchBar"
        type="text"
        placeholder="Start typing ..."
        value={searchValue}
        onChange={(e) => {
          handleSearch(e);
        }}
      />
      <span className="doubleCell">
        <span style={{ color: "white" }}>
          <label>Show events from: </label>
          <select
            className="daysInput"
            name="dates"
            value={days}
            onChange={(e) => {
              handleDate(e);
            }}
          >
            <option value="today">Today</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="day after tomorrow">Day after ....</option>
            <option value="all">All</option>
          </select>
        </span>
        <span style={{ color: "white" }}>
          <label>with percent more than: </label>
          <input
            className="percentInput"
            type="number"
            value={percentLimit}
            onChange={(e) => {
              handlePercent(e);
            }}
          />
        </span>
      </span>
      <Timer />
    </div>
  );
};
export default Header;
