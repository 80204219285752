const toLocalDate = (date) => {
    const localDate = new Date(date)
        .toLocaleString()
        .toUpperCase()
        .replace(" Г.,", "")
        .replace(" Ч.", "");

    return localDate;
}

export default toLocalDate;