import React from "react";

import pickBookieLogo from "../../../../../../utils/pickBookieLogo";

import Odd from "./Odd/Odd";

const Bookie = (props) => {
  const {
    showBookies,
    firstTeamName,
    newFirstTeamName,
    secondTeamName,
    newSecondTeamName,
    name,
    bookie,
    setIsHidden,
    setCopied,
    activeBets,
    handleBets,
    betAmount
  } = props;

  // console.log(bookie, activeBetObj);
  return (
    <div className={showBookies ? "MatchBookies" : "isHidden"}>
      <span style={{ padding: "0px 0px 0px 10px" }}>
        <img
          className="bookieLogoVertical"
          src={pickBookieLogo(name)}
          alt="bookieLogo"
        />
      </span>
      {bookie.odds.map((odd, oddId) => {
        return (
          <Odd
            key={oddId}
            bookie={bookie}
            setIsHidden={setIsHidden}
            setCopied={setCopied}
            activeBets={activeBets}
            handleBets={(event, action, bet) => handleBets(event, action, bet)}
            items={odd.items}
            betAmount={betAmount}
            firstTeamName={firstTeamName}
            newFirstTeamName={newFirstTeamName}
            secondTeamName={secondTeamName}
            newSecondTeamName={newSecondTeamName}
          />
        );
      })}
    </div>
  );
};
export default Bookie;
