import React, { useContext, useState, useEffect } from "react";
import { ScrapeContext } from "../../ScrapeContext";

//utils
import CRUDEvents from "../../../utils/CRUDEvents";
import pickDate from "../../../utils/pickDate";
import toLocalDate from "../../../utils/toLocalDate";
import getHighestPercent from "../../../utils/getHighestPercent";
import filterBookies from "../../../utils/filterBookies";
import arrangeEventsByDate from "../../../utils/arrangeEventsByDate";
import isSelected from "../../../utils/isSelected";

//components
import Loader from "./Loader";
import Match from "./Match/Match";
import Alert from "./Alert";
import ActiveBets from "./ActiveBets/ActiveBets";
import ScrollToTopButton from "./ScrollToTopButton";
import Header from "./Header/Header";
import MatchHeader from "./MatchHeader";

const HighestOdds = () => {
  const scrapeData = useContext(ScrapeContext);
  let loading = scrapeData.scrapes.scrapeResult === undefined;

  let scrapeTime = loading
    ? ""
    : toLocalDate(scrapeData.scrapes.scrapeResult.timestamp);

  const dummyEvent = { firstTeamName: "", secondTeamName: "" };
  let answer, bet;

  //states
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState(pickDate(0));
  const [defDays, setDefDays] = useState("today");
  const [percentLimit, setPercentLimit] = useState(0);
  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(dummyEvent);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    let matches = loading ? [] : scrapeData.scrapes.scrapeResult.matches;
    setEvents(arrangeEventsByDate(matches));
  }, [scrapeData, loading]);

  let days = defDays;

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value.toUpperCase());
  };

  const handleBets = (
    match,
    action,
    answer,
    bet,
    setShowAlert,
    setSelectedEvent,
    selectedEvents,
    setSelectedEvents
  ) => {
    CRUDEvents(
      match,
      action,
      answer,
      bet,
      setShowAlert,
      setSelectedEvent,
      selectedEvents,
      setSelectedEvents
    );
  };

  const handlePercent = (event) => {
    event.preventDefault();
    setPercentLimit(event.target.value);
  };

  const handleDate = (event) => {
    event.preventDefault();
    days = event.target.value;
    setDefDays(days);

    if (days === "today") {
      setDate(pickDate(0));
    }
    if (days === "tomorrow") {
      setDate(pickDate(1));
    }
    if (days === "day after tomorrow") {
      setDate(pickDate(2));
    }
    if (days === "all") {
      setDate("");
    }
  };

  //console.log(selectedEvents);
  return (
    <div className="HighestOdds">
      <Header
        days={days}
        searchValue={searchValue}
        percentLimit={percentLimit}
        handleDate={(e) => handleDate(e)}
        handlePercent={(e) => handlePercent(e)}
        handleSearch={(e) => {
          handleSearch(e);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="eventsContainer">
          <ActiveBets
            events={events}
            selectedEvents={selectedEvents}
            handleBets={(match, action, bet) => {
              handleBets(
                match,
                action,
                answer,
                bet,
                setShowAlert,
                setSelectedEvent,
                selectedEvents,
                setSelectedEvents
              );
            }}
          />
          <MatchHeader matchesLength={events.length} scrapeTime={scrapeTime} />
          {events.map((element, id) => {
            let highestPercent = getHighestPercent(
              element.highestOdds[0].coef,
              element.highestOdds[1].coef,
              element.highestOdds[2].coef
            );
            if (
              filterBookies(
                element.bookies.length,
                element.match,
                element.champName,
                element.categoryName,
                searchValue,
                element.eventDate,
                date,
                highestPercent,
                percentLimit
              )
            ) {
              let date = new Date(element.eventDate)
                .toLocaleString()
                .toUpperCase();

              let time = date
                .substring(date.toLocaleString().indexOf(",") + 1)
                .replace(":00 Ч.", "");

              date = date.substring(0, date.toLocaleString().indexOf(" Г"));

              return (
                <Match
                  key={id}
                  categoryName={element.categoryName}
                  champName={element.champName}
                  eventDate={date}
                  eventTime={time}
                  firstTeamLogo={element.firstTeamLogo}
                  firstTeamName={element.firstTeamName}
                  secondTeamLogo={element.secondTeamLogo}
                  secondTeamName={element.secondTeamName}
                  bookies={element.bookies}
                  highestOdds={element.highestOdds}
                  highestPercent={highestPercent}
                  selectedEvent={isSelected(
                    element.firstTeamName,
                    element.secondTeamName,
                    selectedEvents
                  )}
                  handleBets={(match, action) =>
                    handleBets(
                      match,
                      action,
                      answer,
                      bet,
                      setShowAlert,
                      setSelectedEvent,
                      selectedEvents,
                      setSelectedEvents
                    )
                  }
                />
              );
            } else return null;
          })}
        </div>
      )}
      <ScrollToTopButton />
      <Alert
        show={showAlert}
        buttonTrue="YES"
        buttonFalse="NO"
        message={`Do you really want to delete: "
        ${
          selectedEvent.firstTeamName
            ? selectedEvent.firstTeamName + " vs "
            : "empty bet"
        }
          ${selectedEvent.secondTeamName}"?`}
        handleClick={(answer) => {
          handleBets(
            selectedEvent,
            "delete",
            answer,
            bet,
            setShowAlert,
            setSelectedEvent,
            selectedEvents,
            setSelectedEvents
          );
        }}
      />
    </div>
  );
};
export default HighestOdds;
