import toLocalDate from './toLocalDate';

const filterBookies = (bookiesLength, match, champName, categoryName, searchValue, eventDate, date, highestPercent, percentLimit) => {
    const shouldPass = bookiesLength > 0 &&
        (match.toUpperCase().includes(searchValue) ||
            champName.toUpperCase().includes(searchValue) ||
            categoryName.toUpperCase().includes(searchValue)) &&
        toLocalDate(eventDate).includes(date) &&
        highestPercent > percentLimit

    return shouldPass;
}
export default filterBookies;