import React, { useState, useEffect } from "react";

const Item = (props) => {
  const {
    itemId,
    coef,
    cash,
    setIsHidden,
    setCopied,
    link,
    locked,
    cashHandler,
    coefHandler,
    betAmount,
  } = props;

  const [cashLocal, setCashLocal] = useState("");
  const [coefLocal, setCoefLocal] = useState("");
  const [isCTRLPressed, setIsCTRLPressed] = useState(false);

  useEffect(() => {
    setCashLocal(cash);
    setCoefLocal(typeof coef !== "number" ? "" : Number(coef).toFixed(2));
  }, [coef, cash]);

  window.addEventListener("keydown", function (event) {
    if (event.key === "Control") {
      setIsCTRLPressed(true);
    }
  });

  window.addEventListener("keyup", function (event) {
    if (event.key === "Control") {
      setIsCTRLPressed(false);
    }
  });

  const setLabel = (id) => {
    let label;
    switch (id) {
      case 0:
        label = 1;
        break;
      case 1:
        label = "X";
        break;
      case 2:
        label = 2;
        break;
      default:
        break;
    }
    return label;
  };
  const copyHandler = (e, link) => {
    if (e.type === "click" && isCTRLPressed) {
      navigator.clipboard.writeText(link);
      setIsHidden("notHidden");
      setCopied("Copied!");
      setTimeout(() => {
        setCopied("");
        setIsHidden("isHidden");
      }, 2000);
    }
  };

  const coefHandlerLocal = (e) => {
    e.preventDefault();
    let value = Number(e.target.value);
    setCoefLocal(value);
    coefHandler(value, itemId);
  };

  const cashHandlerLocal = (e) => {
    e.preventDefault();
    let value = Number(e.target.value);
    setCashLocal(value);
    cashHandler(value, itemId);
  };
  return (
    <div style={{ display: "flex", alignItems: "end" }}>
      <span className="tooltipWrapper">
        <span className="doubleCell">
          <label>{setLabel(itemId)}</label>
          <input
            disabled={locked}
            type="number"
            className="tableInputs"
            // href={odd.items[0].oddLink}
            // target="_blank"
            // rel="noopener noreferrer"
            value={coefLocal}
            onChange={(e) => {
              coefHandlerLocal(e);
            }}
            onClick={(e) => copyHandler(e, link)}
          />
        </span>
      </span>
      <span className="doubleCell">
        <label>💵</label>
        <input
          disabled={locked}
          type="number"
          onChange={(e) => {
            cashHandlerLocal(e);
          }}
          className="tableInputs"
          value={cashLocal}
        />
      </span>
      <span className="tooltip">({(betAmount / coef || 0).toFixed(2)})</span>
    </div>
  );
};
export default Item;
