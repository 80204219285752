const pickDate = (add, rev) => {
    let today = new Date();
    let day = today.getDate() + add;
    day = day < 10 ? '0' + day : day
    let month = today.getMonth() + 1;
    month = month < 10 ? '0' + month : month
    let year = today.getFullYear();
    let dateNow = rev ? day + "." + month + "." + year : year + "-" + month + "-" + day;
    return dateNow;
};

export default pickDate;