//images
import BWINLogo from "../images/BWIN-logo.png";
import WINBETLogo from "../images/WINBET-logo.png";
import EIGHTSLogo from "../images/EIGHTS-logo.png";
import SESAMELogo from "../images/SESAME-logo.png";
import ALPHAWINLogo from "../images/ALPHAWIN-logo.png";
import EFBETLogo from "../images/EFBET-logo.png";
import PALMSBETLogo from "../images/PALMSBET-logo.png";
import BET365Logo from "../images/BET365-logo.png";
import BETANOLogo from "../images/BETANO-logo.png";
import IGUBETLogo from "../images/IGUBET-logo.svg";

const pickBookyLogo = (siteName) => {
  if (siteName === "BWIN") {
    return BWINLogo;
  }
  if (siteName === "WINBET") {
    return WINBETLogo;
  }
  if (siteName === "8888") {
    return EIGHTSLogo;
  }
  if (siteName === "SESAME") {
    return SESAMELogo;
  }
  if (siteName === "ALPHAWIN") {
    return ALPHAWINLogo;
  }
  if (siteName === "EFBET") {
    return EFBETLogo;
  }
  if (siteName === "PALMSBET") {
    return PALMSBETLogo;
  }
  if (siteName === "BET365") {
    return BET365Logo;
  }
  if (siteName === "BETANO") {
    return BETANOLogo;
  }
  if (siteName === "IGUBET") {
    return IGUBETLogo;
  }
};

export default pickBookyLogo;