import React, { useState, useEffect, useContext } from "react";
import { ScrapeContext } from "../../../ScrapeContext";
import spinningArrows from "../../../../images/spinning-arrows.gif";

const Timer = () => {
  const scrapeData = useContext(ScrapeContext);
  let fetchTime = scrapeData.scrapes.fetchTime;
  let fetchPeriod = scrapeData.scrapes.fetchPeriod;
  let deadline = Date.parse(fetchTime) + fetchPeriod-1000;

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTimeUntil = (deadline) => {
    let time = deadline - Date.parse(new Date());
    if (time < 0 || isNaN(time)) {
      return;
    } else {
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }

    // setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    // setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);
    return () => {
      getTimeUntil(deadline);
    };
  }, [deadline]);

  return (
    <div className="timer">
      <span className="doubleCell">
        <span style={{ color: "white" }}>update after</span>
        <div style={{ color: "white" }}>
          <h1>
            {minutes + seconds < 1 ? (
              <span>
                <img className="spiner" src={spinningArrows} alt="loader" />
              </span>
            ) : (
              <span>
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </span>
            )}
          </h1>
        </div>
      </span>
    </div>
  );
};
export default Timer;
