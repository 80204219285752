const isSelected = (firstName, secondName, selectedEvents) => {
    const selected =
        selectedEvents.length > 0 &&
        selectedEvents.some(
            (selEve) =>
                selEve.firstTeamName === firstName &&
                selEve.secondTeamName === secondName
        );
    return selected;
};

export default isSelected;