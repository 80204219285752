const getHighestPercent = (firstCoef, secondCoef, thirdCoef) => {
    const highestPercent = (
        (100 /
            (100 / Number(firstCoef) +
                100 / Number(secondCoef) +
                100 / Number(thirdCoef))) *
        100
    ).toFixed(2)

    return highestPercent;
}
export default getHighestPercent;