import { useState } from "react";

//styles
import "../pages/Home.css";
import "../components/Home/HighestOdds/Loader.css";

import HighestOdds from "../components/Home/HighestOdds/HighestOdds";

import Login from "../components/Login";

const user = "BAT_GIORGI";
const pass = "ANDIBUL_MORKOV";

export default function Home() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const handleUser = (event) => {
    event.preventDefault();
    setUsername(event.target.value.toUpperCase());
  };
  const handlePass = (event) => {
    event.preventDefault();
    setPassword(event.target.value.toUpperCase());
  };

  const buttonHandler = () => {
    if (username === user && password === pass) {
      setShow(true);
    } else alert("Try again 🥕 ;)");
  };

  return (
    <div className="Home">
      {show ? (
        <HighestOdds />
      ) : (
        <Login
          username={username}
          handleUser={(e) => handleUser(e)}
          password={password}
          handlePass={(e) => handlePass(e)}
          buttonHandler={buttonHandler}
        />
      )}
    </div>
  );
}
