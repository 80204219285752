const generateBookies = (bookies) => {
    const bookiesNames = [
        "BWIN",
        "8888",
        "SESAME",
        "WINBET",
        "ALPHAWIN",
        "EFBET",
        "BET365",
        "PALMSBET",
        "BETANO",
        "IGUBET"
    ];
    const mappedBookies = [];
    bookiesNames.forEach((name) => {
        mappedBookies.push(bookies.some((bookie) =>
            bookie.name === name ?
                true
                : false
        ) ? bookies.find(bookie => bookie.name === name) :
            {
                name: name,
                odds: [
                    {
                        type: "Краен резултат",
                        items: [
                            { name: 1, oddLink: "", coef: '' },
                            { name: "X", oddLink: "", coef: '' },
                            { name: 2, oddLink: "", coef: '' },
                        ],
                    },
                ],
            });
    });
    mappedBookies.map(bookie => bookie.odds.map(odd => odd.items.map((item, id) => (id === 0 ? item.name = 1 : null, id === 1 ? item.name = "X" : null, id === 2 ? item.name = 2 : null, item.hasOwnProperty("cash") ? null : item.cash = ''))))
    return mappedBookies;
}
export default generateBookies;