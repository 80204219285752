import { useState } from "react";

import pickBookieLogo from "../../../../utils/pickBookieLogo";

import Bookies from "./Bookies/Bookies";

const Match = (props) => {
  let {
    eventDate,
    firstTeamLogo,
    firstTeamName,
    secondTeamLogo,
    secondTeamName,
    champName,
    categoryName,
    bookies,
    highestOdds,
    highestPercent,
    eventTime,
    handleBets,
    selectedEvent,
  } = props;

  const [showBookies, setShowBookies] = useState(false);
  const [copied, setCopied] = useState("");
  const [isHidden, setIsHidden] = useState("isHidden");

  const copyHandler = (e, link) => {
    e.stopPropagation();
    navigator.clipboard.writeText(link);
    setIsHidden("notHidden");
    setCopied("Copied!");
    setTimeout(() => {
      setCopied("");
      setIsHidden("isHidden");
    }, 2000);
  };

  const showBookiesHandler = (e) => {
    e.stopPropagation();
    setShowBookies(!showBookies);
  };
  const handleSelected = (e) => {
    selectedEvent
      ? handleBets(
          {
            firstTeamName,
            secondTeamName,
          },
          "delete"
        )
      : handleBets(
          {
            firstTeamName,
            secondTeamName,
            eventTime,
            eventDate,
          },
          "create"
        );
  };

  return (
    <div
      className={selectedEvent ? "selectedMatch" : "Match"}
      onClick={(e) => handleSelected(e)}
    >
      <span className="matchContainer">
        <span className="timeAndNameContainer">
          <div className="doubleCell">
            <span className="timeContainer">{eventTime}</span>
            <span className="dateContainer">{eventDate}</span>
          </div>
          <span className="teamsNameContainer">
            <div>
              <img className="teamLogo" src={firstTeamLogo} alt="home logo" />
              <span className="matchName">{firstTeamName}</span>
            </div>
            <div>
              <img className="teamLogo" src={secondTeamLogo} alt="away logo" />
              <span className="matchName">{secondTeamName}</span>
            </div>
          </span>
        </span>
        <span className="oddsValueContainer">
          <span
            style={{
              lineHeight: "140px",
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: 1000,
              background: "none",
              marginRight: "12px",
            }}
          >
            {highestPercent+" %"}
          </span>
          {highestOdds && highestOdds.length === 3
            ? highestOdds.map((bookie, id) => {
                let link = bookie.oddLink;
                return (
                  <div style={{ display: "flex" }} key={id}>
                    <p className={isHidden}>{copied}</p>
                    <div className="oddsContainer">
                      <img
                        className="bookieLogo"
                        src={pickBookieLogo(bookie.name)}
                        alt="bookie logo"
                      />
                      <span
                        className="odds"
                        // href={odd.items[0].oddLink}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        onClick={(e) => copyHandler(e, link)}
                      >
                        <span>
                          {bookie.coef > 0 ? bookie.coef.toFixed(2) : 0}
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })
            : null}
        </span>
      </span>
      <span onClick={(e) => showBookiesHandler(e)} className="buttonMore">
        {showBookies ? "⬆️" : "⬇️"}
        <span className="categories"> bookies [{bookies.length}] </span>
      </span>
      <span className="categories"> 🏆 {champName}</span>
      <span className="categories"> ({categoryName})</span>
      <hr />
      {showBookies ? <Bookies bookies={bookies} /> : null}
      <hr />
    </div>
  );
};
export default Match;
