import React from "react";
import { useState } from "react";
import pickBookieLogo from "../../../../../utils/pickBookieLogo";

const Bookies = (props) => {
  const { bookies } = props;
  const [copied, setCopied] = useState("");
  const [isHidden, setIsHidden] = useState("isHidden");

  const copyHandler = (link) => {
    navigator.clipboard.writeText(link);
    setIsHidden("notHidden");
    setCopied("Copied!");
    setTimeout(() => {
      setCopied("");
      setIsHidden("isHidden");
    }, 2000);
  };

  return (
    <div className="BookiesTable">
      <p
        style={{
          fontSize: "0.7rem",
          background: "rgba(219, 219, 219, 0.6)",
        }}
      >
        Match Bookies
      </p>

      {bookies.map((bookie, id) => {
        return (
          <div className="MatchBookies" key={id}>
            <span style={{ marginLeft: "55px" }}>
              <img
                className="bookieLogoVertical"
                src={pickBookieLogo(bookies[id].name)}
                alt="bookieLogo"
              />
            </span>
            {bookie.odds.map((odd, id) => {
              let percent = (
                (100 /
                  (100 / odd.items[0].coef +
                    100 / odd.items[1].coef +
                    100 / odd.items[2].coef)) *
                100
              ).toFixed(2);
              let link = odd.items[0].oddLink;
              return (
                <div className="tableRow" key={id}>
                  <span
                    style={{
                      lineHeight: "20px",
                      textAlign: "center",
                      fontSize: "1rem",
                      fontWeight: 1000,
                      margin: "0 10px",
                    }}
                  >
                    {percent > 0 ?  percent + " %" : "none %"}
                  </span>
                  <span>1</span>
                  <span
                    className="odds"
                    // href={odd.items[0].oddLink}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    onClick={() => copyHandler(link)}
                  >
                    {odd.items[0].coef > 0 ? odd.items[0].coef.toFixed(2) : 0}
                  </span>
                  <span>X</span>
                  <span
                    className="odds"
                    // href={odd.items[0].oddLink}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    onClick={() => copyHandler(link)}
                  >
                    {odd.items[1].coef > 0 ? odd.items[1].coef.toFixed(2) : 0}
                  </span>
                  <span>2</span>
                  <span
                    className="odds"
                    // href={odd.items[0].oddLink}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    onClick={() => copyHandler(link)}
                  >
                    {odd.items[2].coef > 0 ? odd.items[2].coef.toFixed(2) : 0}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
      <span className={isHidden}>{copied}</span>
    </div>
  );
};
export default Bookies;
